<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="4">
								Konfigurasi
							</CCol>
							<CCol md="8" class="text-right">
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="12">
								<CRow>
									<CCol sm="12">
										<table class="w-100 table-tr-spacing">
											<tbody>
												<tr>
													<th style="width: 250px;"><label for=""><b>Tutup Pengajuan</b></label></th>
													<td style="width: 15px;"> : </td>
													<td>
														<CSwitch class="mx-1" color="primary" :checked="open_apli_value == 'open'" shape="pill" v-bind="labelTxt" @update:checked="(val) => val ? open_apli_value = 'open' : open_apli_value = 'close'"/> 
													</td>
												</tr>
												<tr>
													<th style="width: 250px;"><label for=""><b>Popup Info Aplikasi</b></label></th>
													<td style="width: 15px;"> : </td>
													<td>
														<CSwitch class="mx-1" color="primary" :checked="popup_apli_value == 'open'" shape="pill" v-bind="labelTxt" @update:checked="(val) => val ? popup_apli_value = 'open' : popup_apli_value = 'close'"/> 
													</td>
												</tr>
												<tr>
													<th style="width: 250px;"><label for=""><b>Keterangan Popup</b></label></th>
													<td style="width: 15px;"> : </td>
													<td>
														<textarea class="form-control" v-model="popup_keterangan_value"></textarea>
													</td>
												</tr>
												<tr>
													<th style="width: 250px;"><label for=""><b>Checklist Permohonan</b></label></th>
													<td style="width: 15px;"> : </td>
													<td>
														<textarea class="form-control" v-model="checklist_permohonan"></textarea>
													</td>
												</tr>
												<tr>
													<th style="width: 250px;"><label for=""><b>Disclaimer Permohonan</b></label></th>
													<td style="width: 15px;"> : </td>
													<td>
														
													</td>
												</tr>
												<tr>
													<td colspan="3">
														<table class="table table-bordered table-sm">
															<thead class="bg-sapphire">
																<tr>
																	<th style="width: 15px;">No</th>
																	<th style="width: 450px;">Module</th>
																	<th>Disclaimer</th>
																	<th style="width: 50px;"><div class="btn btn-sm btn-success" v-on:click="addRowDisclaimer"><i class="fa fa-plus"></i></div></th>
																</tr>
															</thead>
															<tbody>
																<tr v-for="(value_rows_disclaimer, key_rows_disclaimer) in rows_disclaimer" :key="key_rows_disclaimer">
																	<td>{{key_rows_disclaimer+1}}</td>
																	<td>
																		<v-select :filter="searchModule" :options="list_module" v-model="value_rows_disclaimer.module">
																			<template v-slot:option="value_list_module">
																				{{ value_list_module.m_kategori }} - {{ value_list_module.m_nama }}
																			</template>
																			<template #selected-option="value_list_module">
																				{{ value_list_module.m_kategori }} - {{ value_list_module.m_nama }}
																			</template>
																		</v-select>
																	</td>
																	<td>
																		<input type="text" class="form-control" v-model="value_rows_disclaimer.disclaimer_label" placeholder="Disclaimer">
																	</td>
																	<td>
																		<div class="btn btn-sm btn-danger" v-on:click="deleteRowDisclaimer(key_rows_disclaimer, value_rows_disclaimer)"><i class="fa fa-minus"></i></div>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<br />
					</CCardBody>
					<CCardFooter>
						<div class="text-right">
							<button v-on:click="storeProcess" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
						</div>
					</CCardFooter>
				</CCard>
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="4">
								Generate SK
							</CCol>
							<CCol md="8" class="text-right">
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="12">
								<CRow>
									<CCol sm="12">
										<table class="table table-bordered table-sm">
											<thead class="bg-sapphire">
												<tr>
													<th>No</th>
													<th>Kode Permohonan</th>
													<th><div class="btn btn-sm btn-success" v-on:click="addRowKode"><i class="fa fa-plus"></i></div></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(value_rows_kode, key_rows_kode) in rows_kode" :key="key_rows_kode">
													<td>{{key_rows_kode+1}}</td>
													<td>
														<input type="text" class="form-control" v-model="value_rows_kode.kode_permohonan" placeholder="HORTI-123">
													</td>
													<td>
														<div class="btn btn-sm btn-danger" v-on:click="deleteRow(key_rows_kode, value_rows_kode)"><i class="fa fa-minus"></i></div>
													</td>
												</tr>
											</tbody>
										</table>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<br />
					</CCardBody>
					<CCardFooter>
						<div class="text-right">
							<button v-on:click="storeGenerateSK" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-cogs"></i> Generate</button>
						</div>
					</CCardFooter>
				</CCard>
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="4">
								Update Data Perusahaan
							</CCol>
							<CCol md="8" class="text-right">
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						<div class="form-group">
							<label for="">Semua Perusahaan</label>
							<v-select :options="is_all_option" label="label" v-model="is_all_selected"></v-select>
						</div>
						<template>

						
							<div v-if="is_all_selected.option != 'semua'">
								<table class="table table-bordered table-sm">
									<thead class="bg-sapphire">
										<tr>
											<th>No</th>
											<th>NIB Perusahaan</th>
											<th><div class="btn btn-sm btn-success" v-on:click="addRowNIB"><i class="fa fa-plus"></i></div></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value_rows_nib, key_rows_nib) in rows_nib" :key="key_rows_nib">
											<td>{{key_rows_nib+1}}</td>
											<td>
												<!-- <input type="text" class="form-control" v-model="value_rows_nib.nib_perusahaan" placeholder="9120405732598"> -->
												<v-select :filter="searchPerusahaan" :options="list_perusahaan" v-model="value_rows_nib.nib_perusahaan">
													<template v-slot:option="value_list_perusahaan">
														{{ value_list_perusahaan.nib }} - {{ value_list_perusahaan.p_nama }}
													</template>
													<template #selected-option="value_list_perusahaan">
														{{ value_list_perusahaan.nib }} - {{ value_list_perusahaan.p_nama }}
													</template>
												</v-select>
												
											</td>
											<td>
												<div class="btn btn-sm btn-danger" v-on:click="deleteRowNIB(key_rows_nib, value_rows_nib)"><i class="fa fa-minus"></i></div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-else>
								<small>*) Pilih Per Perusahaan untuk update data berdasarkan NIB Perusahaan</small>
							</div>
						</template>
						
						<br />
					</CCardBody>
					<CCardFooter>
						<div class="text-right">
							<button v-on:click="storeUpdatePerusahaan" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
						</div>
					</CCardFooter>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	const dataintable = [];
	export default {
		name: "AllKonfigurasi",
		components: {},
		data() {
			return {
				open_apli_value: '',
				popup_apli_value: '',
				popup_keterangan_value : '',
				list_perusahaan:"",
				open_apli : [],
				popup_apli : [],
				popup_keterangan : [],
				list_module: [],
				rows_disclaimer: [{
					module: [],
					disclaimer_label: ''
				}],
				checklist_permohonan: '',
				labelTxt: {
			        labelOn: 'yes',
			        labelOff: 'no'
			    },
			    rows_kode: [],
				rows_nib: [],
				is_all_option: [
					{
						label : 'Semua',
						option : 'semua'
					},
					{
						label : 'Per NIB',
						option : 'per_nib'
					}
				],
				is_all_selected: {
					label : 'Semua',
					option : 'semua'
				},
				

			}
		},
		methods: {
			searchPerusahaan(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["nib", "p_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
			searchModule(options, search) {
		      const fuse = new Fuse(options, {
		        keys: ["m_kategori", "m_nama"],
		        shouldSort: true
		      });
		      return search.length
		        ? fuse.search(search).map(({ item }) => item)
		        : fuse.list;
		    },
			addRowKode:  function () {
				console.log(this.rows_kode);
				this.rows_kode.push({
	                kode_permohonan: ''
	            });
			},
			addRowDisclaimer() {
				this.rows_disclaimer.push({
					module: [],
					disclaimer_label: ''
				});
			},
			deleteRow(index, rows_kode_at) {
	            var idx = this.rows_kode.indexOf(rows_kode_at);
	            if (idx > -1) {
	                this.rows_kode.splice(idx, 1);
	            }
	        },
			deleteRowDisclaimer(index, rows_kode_at) {
	            var idx = this.rows_disclaimer.indexOf(rows_kode_at);
	            if (idx > -1) {
	                this.rows_disclaimer.splice(idx, 1);
	            }
	        },
			addRowNIB:  function () {
				this.rows_nib.push({
	                nib_perusahaan: ''
	            });
			},
			deleteRowNIB(index, rows_nib_at) {
	            var idx = this.rows_nib.indexOf(rows_nib_at);
	            if (idx > -1) {
	                this.rows_nib.splice(idx, 1);
	            }
	        },
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				var sendded = [
					{
					 	'id': 1,
					 	'k_aksi': this.open_apli_value,
					},
					{
					 	'id': 2,
					 	'k_aksi': this.popup_apli_value,
					},
					{
					 	'id': 3,
					 	'k_aksi': this.popup_keterangan_value,
					},
					// {
					//  	'id': 4,
					//  	'k_aksi': JSON.stringify(this.rows_disclaimer),
					// },
					{
					 	'id': 5,
					 	'k_aksi': this.checklist_permohonan,
					},
				];
				var FormData = require('form-data');
				var data = new FormData();
				data.append('konfig_json', JSON.stringify(sendded));
				data.append('disclaimer_data', JSON.stringify(this.rows_disclaimer));
				var config = {
				  method: 'post',
				  url: this.apiLink+'api/crud/konfigurasi',
				  headers: { 
					'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Konfigurasi Berhasil Diubah",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								window.location.reload();
								// self.$router.push({name: 'AllFaq'});
							}
						});
					}
				});
			},
			storeGenerateSK: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('kode_permohonan', JSON.stringify(this.rows_kode));
				data.append('generate_from', 'konfig_generate_sk');
				var config = {
				  method: 'post',
				  url: this.apiLink + 'api/autogen',
				  headers: { 
					'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) { 
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Generate SK Berhasil",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								window.location.reload();
								// self.$router.push({name: 'AllFaq'});
							}
						});
					}
				}).catch((error) => {
				  	if (error) {
				  		Swal.fire({
							icon: 'error',
							title: 'Gagal',
							text: 'Pastikan permohonan sudah memiliki nomor dan tanggal sk',
						});
				  	}
				});
			},
			storeUpdatePerusahaan: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				let array_nib = [];
				$.each(this.rows_nib, function (indexInArray, valueOfElement) { 
					array_nib[indexInArray] = valueOfElement.nib_perusahaan.nib;
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('nib', JSON.stringify(array_nib));
				data.append('is_all', self.is_all_selected.option);
				var config = {
				  method: 'post',
				  url: this.apiLink + 'api/crud/perusahaan/update_batch_perusahaan',
				  headers: { 
					'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) { 
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Data perusahaan berhasil di update",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.rows_nib = [];
								// self.$router.push({name: 'AllFaq'});
							}
						});
					}
				}).catch((error) => {
				  	if (error) {
				  		Swal.fire({
							icon: 'error',
							title: 'Gagal',
							text: 'Terjadi Kesalahan',
						});
				  	}
				});
			},
		},
		
		beforeCreate() {
			Swal.fire({
				title: '<i class="fa fa-refresh fa-spin"></i>',
				text: "Loading...",
				showConfirmButton: false,
			});
			axios.get(this.apiLink + "api/crud/konfigurasi", {
				headers: {
					Authorization: "Bearer " + this.access_token,
				},
			})
			.then((response) => {
				var res_konfigurasi = response.data;
				var res_konfigurasi_data = res_konfigurasi.data[0];
				// console.log(res_konfigurasi_data);
				if (res_konfigurasi.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_konfigurasi.data.message
					});
				}
				else{
					this.loadingTable = false;
					this.open_apli = res_konfigurasi_data.find(options => {
						if (options.id === 1) {
							return options
						}
					});
					this.popup_apli = res_konfigurasi_data.find(options => {
						if (options.id === 2) {
							return options
						}
					});
					this.popup_keterangan = res_konfigurasi_data.find(options => {
						if (options.id === 3) {
							return options
						}
					});
					var konfig_checklist = res_konfigurasi_data.find(options => {
						if (options.id === 5) {
							return options
						}
					});
					this.open_apli_value = this.open_apli.k_aksi;
					this.popup_apli_value = this.popup_apli.k_aksi;
					this.popup_keterangan_value = this.popup_keterangan.k_aksi;
					this.checklist_permohonan = konfig_checklist.k_aksi;
					this.rows_disclaimer = res_konfigurasi.data[1];
					swal.close();
				}
			});
			axios.get(this.apiLink + "api/master/perusahaan", {
                params: {
					p_json: 'not_null',
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_perusahaan = response.data;
                var res_perusahaan_data = res_perusahaan.data;
                if (res_perusahaan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_perusahaan.data.message
                    });
                }
                else{
                    this.list_perusahaan = res_perusahaan_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
			axios.get(this.apiLink + "api/master/module", {
                params: {
					p_json: 'not_null',
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_module = response.data;
                var res_module_data = res_module.data;
                if (res_module.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_module.data.message
                    });
                }
                else{
                    this.list_module = res_module_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
		},
	};
</script>